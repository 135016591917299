import FlexBox, { FlexBoxComponentProps } from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import Link from 'next/link';
import { useState } from 'react';
import AutoplayImageSlider from '../autoplayImageSlider';
import { IProductData } from '../search-results/model';
import Arrow from '@/assets/product-tile-four-arrow.svg';
import { PdpUrlHelper } from '@/utils/seo-url-helper';
import TrashIcon from '@/assets/trash_icon.svg';
import { LineOfProduct } from '@/utils/enums';
import ProductBadge from '@/components/ProductBadge';

const lopLabelColor = new Map([
	['RENT', 'Danger -2'],
	['BUY_NEW', 'Minty 0'],
	['BUY_REFURBISHED', 'Minty 0'],
]);

const OOSContainerStyle = {
	filter: 'grayscale(1)',
	':hover': {
		outline: `2px solid ${getColor('Gray -4')}`,
	},
};

const getStockBasedContainerStyle = (isAvailable: boolean) => {
	if (!isAvailable) {
		return OOSContainerStyle;
	}

	return {
		':hover': {
			outline: `2px solid ${getColor('Minty 0')}`,
		},
	};
};

export type RelatedProductTileProps = {
	data: IProductData;
	isWishlist?: boolean;
	deleteItemFromWishlist?: (id: number) => void;
	with?: {
		promiseDate?: boolean;
		variantsAvailable?: boolean;
	};
	WrapperProps?: FlexBoxComponentProps;
};

const reduceLopLabelContent = (content: string) => {
	return LineOfProduct[content as keyof typeof LineOfProduct]
		.replace('Buy ', '')
		.toLocaleUpperCase();
};

function RelatedProductTile(props: RelatedProductTileProps) {
	const { data } = props;
	const [hovered, setHovered] = useState(false);

	const getPrice = () => {
		const price =
			data?.lineOfProduct == 'RENT'
				? data?.pricing?.monthlyRental?.displayValue
				: data?.pricing?.basePrice?.displayValue;

		return price;
	};

	const markCardHovered = () => {
		data?.heroes && setHovered(true);
	};

	const markCardUnHovered = () => {
		setHovered(false);
	};

	const getProductBadge = () => {
		let badge = null;

		if (!props.data.available) {
			badge = (
				<ProductBadge badge={{ name: 'out-of-stock', label: 'Out of stock' }} />
			);
		} else if (props?.data?.badge) {
			badge = <ProductBadge badge={props?.data?.badge} />;
		}

		if (!badge) return;

		return (
			<Box
				sx={{
					position: 'relative',
					left: '-8px',
					height: '20px',
				}}
			>
				{badge}
			</Box>
		);
	};

	return (
		<FlexBox
			{...props.WrapperProps}
			sx={{
				direction: 'row',
				borderRadius: '8px',
				outline: 'none',
				...getStockBasedContainerStyle(props?.data?.available),
				...props.WrapperProps?.sx,
			}}
			onMouseOver={markCardHovered}
			onMouseOut={markCardUnHovered}
			onBlur={markCardUnHovered}
		>
			<Link href={props.data.permalink} style={{ width: '100%' }}>
				<Box sx={{ width: '100%', marginBottom: '-50%', position: 'relative' }}>
					<Box
						sx={{
							filter: !props?.data?.available ? 'grayscale(1)' : 'grayscale(0)',
							borderRadius: '8px 8px 0px 0px',
							overflow: 'hidden',
						}}
					>
						<LencoImage
							url={data?.thumbnail?.url}
							aspectRatio={data?.thumbnail?.aspectRatio || 1}
						/>
					</Box>
					{getProductBadge()}
				</Box>
				<Box
					sx={{
						background: getColor(''),
						padding: 'calc(60% - 24px) 24px 24px 24px',
						borderRadius: '8px',
						boxShadow: '0px 8px 16px rgba(34, 34, 34, 0.08)',
					}}
				>
					{LineOfProduct[
						props?.data?.lineOfProduct as keyof typeof LineOfProduct
					].length > 0 && (
						<LencoTypography
							sx={{
								color: getColor(
									lopLabelColor.get(props?.data?.lineOfProduct) || ''
								),
								display: 'inline-block',
								left: 0,
								borderTopRightRadius: '16px',
								borderBottomRightRadius: '16px',
							}}
							variant='Small_Medium'
						>
							{reduceLopLabelContent(props.data?.lineOfProduct)}
						</LencoTypography>
					)}
					<LencoTypography
						sx={{
							...getStyle('H11_Medium'),
							color: getColor('Gray 0'),
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 2,
							overflow: 'hidden',
							minHeight: '46.8px',
						}}
					>
						{data?.title}
					</LencoTypography>
					<FlexBox
						justifyContent='start'
						alignItems={'center'}
						sx={{ mt: '12px' }}
					>
						<LencoTypography
							sx={{
								...getStyle('H14_SemiBold'),
								color: getColor('Gray 0'),
								mr: '8px',
							}}
						>
							{getPrice()}
						</LencoTypography>
						<LencoTypography
							sx={{
								filter: !props?.data?.available
									? 'grayscale(1)'
									: 'grayscale(0)',
								...getStyle('Small_SemiBold'),
								padding: '6px 16px',
								background: getColor('Yellow -3'),
								color: getColor('Gray 0'),
								borderRadius: '16px',
							}}
						>
							{data?.pricing?.discountPercentage?.displayValue}
						</LencoTypography>
						<LencoTypography
							sx={{
								...getStyle('Small_SemiBold'),
								margin: '0 8px',
								color: getColor('Gray -3'),
								textDecoration: 'line-through',
							}}
						>
							{data?.pricing?.strikePrice?.displayValue}
						</LencoTypography>
					</FlexBox>
					{props.with?.promiseDate && (
						<LencoTypography
							sx={{
								...getStyle('Small_Regular'),
								color: getColor('Gray -1'),
								margin: '6px 0',
							}}
						>
							{data?.delivery?.displayValue}
						</LencoTypography>
					)}
					<FlexBox
						justifyContent={
							!props.with?.variantsAvailable ? 'flex-end' : 'space-between'
						}
						alignItems={'center'}
					>
						{props.with?.variantsAvailable && (
							<LencoTypography
								sx={{
									...getStyle('Small_Medium'),
									color: getColor('Gray -1'),
									height: '18px',
								}}
							>
								{data?.variants?.count?.displayValue}
							</LencoTypography>
						)}
					</FlexBox>
				</Box>
			</Link>
		</FlexBox>
	);
}

export default RelatedProductTile;
