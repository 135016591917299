import CategoryTile from "@/components/CategoryTile";
import { ICategoryTile } from "@/components/CategoryTile/model";
import { IImageTile1 } from "@/components/ImageTile1/IImageTile1";
import ImageTile1 from "@/components/ImageTile1/ImageTile1";
import OfferTile from "@/components/offerTile";
import { IOfferData } from "@/components/offerTile/model";
import { IProductTile1 } from "@/components/ProductTile1/IProductTile1";
import ProductTile1 from "@/components/ProductTile1/ProductTile1";
import { IProductTile2 } from "@/components/ProductTile2/IProductTile2";
import ProductTile2 from "@/components/ProductTile2/ProductTile2";
import ProductTileFour from '@/components/ProductTile4';
import RelatedProductTile, { RelatedProductTileProps } from '@/components/RelatedProductTile';
import { IShowcaseWidget1 } from "@/components/ShowcaseWidget1/IShowcaseWidget1";
import ShowcaseWidget1 from "@/components/ShowcaseWidget1/ShowcaseWidget1";
import SpotlightWidget from "@/components/spotlight-widget";
import React from "react";

const components = {
  IMAGE_TILE: {
    component: ImageTile1,
    keyGen: (props: IImageTile1) => {
      return props?.data?.image.url;
    },
  },
  OFFER_TILE: {
    component: OfferTile,
    keyGen: (props: IOfferData) => {
      return props?.data?.code;
    },
  },
  PRODUCT_TILE_1: {
    component: ProductTile1,
    keyGen: (props: IProductTile1) => {
      return props?.data.permalink;
    },
  },
  PRODUCT_TILE_2: {
    component: ProductTile2,
    keyGen: (props: IProductTile2) => {
      return props?.data.permalink;
    },
  },
  PRODUCT_TILE_3: {
    component: ProductTile1,
    keyGen: (props: IProductTile1) => {
      return props?.data.permalink;
    },
  },
  RELATED_PRODUCT_PRODUCT_TILE: {
    component: (props: RelatedProductTileProps) => RelatedProductTile({ ...props, WrapperProps: { sx: { width: 'calc(100% - 10px)', m: '16px 8px' } } }),
    keyGen: (props: IProductTile1) => {
      return props?.data.permalink;
    },
  },
  SHOWCASE_WIDGET_ITEM_DATA: {
    component: ShowcaseWidget1,
    keyGen: (props: IShowcaseWidget1) => {
      return props?.data?.permalink;
    },
  },
  //   PRODUCT_TILE_4: {
  //     component: ProductTileVariantFour,
  //     keyGen: (props: IProductTile) => {
  //       return props?.data?.permalink;
  //     },
  //   },
  CATEGORY_TILE: {
    component: CategoryTile,
    keyGen: (props: ICategoryTile) => {
      return props?.data?.label;
    },
  },

};

export const componentMapper = (component: any) => {
  try {
    if (components[component.type as keyof typeof components]) {
      return {
        component: components[component.type as keyof typeof components].component,
        key: components[component.type as keyof typeof components].keyGen(
          component
        ),
      };
    }
    return {
      component: React.Fragment,
      key: Math.random()
    };
  } catch (error) {
    return {
      component: React.Fragment,
      key: Math.random()
    };
  }

};
