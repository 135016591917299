import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { Box, IconButton } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { IShowcaseWidget1 } from './IShowcaseWidget1';
import ForwardArrowIcon from '@/assets/Arrow_with_minty_background.svg';
import Link from 'next/link';
import { getStyle } from '@/utils/theme/fonts';

export default function ShowcaseWidget1(props: IShowcaseWidget1) {
	const { data } = props;

	const ShowcaseWidget1Reducer = () => {
		return {
			image: data.image,
			label: data.label,
			permalink: props?.cta?.data?.actionData?.deeplinkPath,
			specifications: data.specifications,
		};
	};

	const ShowcaseWidget1Data = ShowcaseWidget1Reducer();

	return (
		<Link
			href={ShowcaseWidget1Data.permalink || '#'}
			style={{ width: '100%', position: 'relative',display:'block' }}
		>
			{/* <FlexBox justifyContent={'center'} direction='column'> */}
			<Box
				sx={{
					position: 'absolute',
					bottom: 20,
					left: '50%',
					width: '80%',
					transform: 'translate(-50%)',
					zIndex: 2,
				}}
			>
				<FlexBox
					direction='column'
					sx={{
						justifyContent: 'space-between',
						width: '100%',
						padding: '24px',
						background: getColor(''),
						borderRadius: '8px',
					}}
				>
					<Box>
						<LencoTypography variant='H11_Regular' color={getColor('Gray 0')}>
							{ShowcaseWidget1Data.label}
						</LencoTypography>
					</Box>
					<FlexBox justifyContent={'space-between'} width='100%'>
						<Box>
							<LencoTypography
								sx={{
									...getStyle('Small_Regular'),
									color: getColor('Gray -1'),
									whiteSpace: 'nowrap',
								}}
							>
								{ShowcaseWidget1Data.specifications.startingFromPrice}
							</LencoTypography>
							<LencoTypography
								variant='Small_Medium'
								color={getColor('Gray -2')}
								sx={{ whiteSpace: 'nowrap' }}
							>
								{ShowcaseWidget1Data.specifications.productCount}
							</LencoTypography>
						</Box>
						<Box>
							{' '}
							<ForwardArrowIcon />
						</Box>
					</FlexBox>
					<Box></Box>
				</FlexBox>
			</Box>
			{/* <Box sx={{ width: '100%'}}> */}
			<LencoImage
				url={ShowcaseWidget1Data.image.url}
				aspectRatio={ShowcaseWidget1Data.image.aspectRatio}
			/>
			{/* </Box> */}
			{/* </FlexBox> */}
		</Link>
	);
}
