import FlexBox from '@/atomic-components/FlexBox';
import WidgetFooter from '@/components/Footers/WidgetFooter';
import WidgetHeader from '@/components/Headers/WidgetHeader';
import { componentMapper } from '@/utils/componentMapper';
import { Box } from '@mui/material';

function BannerWidget(props: any) {
	const { components } = props.data;

	return (
		<Box>
			{props?.data?.headers && <WidgetHeader {...props.data.headers} />}
			<Box>
				{components?.map((component: any, index: number) => {
					const DynamicComponent = componentMapper(component);
					return (
						<Box
							key={`${DynamicComponent.key}-${index}`}
							sx={{ paddingLeft: index == 0 ? '16px' : '0' }}
						>
							<DynamicComponent.component {...component} />
						</Box>
					);
				})}
			</Box>
			{props?.data?.footer && <WidgetFooter {...props?.data?.footer} />}
		</Box>
	);
}

export default BannerWidget;
