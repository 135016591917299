import FlexBox from "@/atomic-components/FlexBox";
import { LencoTypography } from "@/atomic-components/LencoTypography";
import { getColor } from "@/utils/theme/colors";
import { getStyle } from "@/utils/theme/fonts";
import { Box } from "@mui/material";
import { useState } from "react";
import CopyIcon from '@/assets/pdp/copyIcon.svg';


function CopyCode(props:any) {
    const [codeCopied,setCodeCopied]=useState(false)
	const ActionOnCopyCode = (offerCode: string) => {
		navigator.clipboard.writeText(offerCode);
		setCodeCopied(true);

	};
    return (  
        <Box>
            {!codeCopied ? (
									<FlexBox
										sx={{
											border: `1px solid ${getColor('Gray -4')}`,
											height: '40px',
											width: '120px',
											justifyContent: 'center',
											alignItems: 'center',
											cursor: 'pointer',
											backgroundColor: '#FFFFFF',
											borderRadius: '8px',
										}}
										onClick={() => {
											props?.code && ActionOnCopyCode(props?.code as string);
										}}
									>
										<CopyIcon />
										<LencoTypography
											sx={{
												...getStyle('Small_Medium'),
												color: getColor('Minty 0'),
												paddingLeft: '8px',
											}}
										>
											Copy Code
										</LencoTypography>
									</FlexBox>
								) : (
									<FlexBox
										sx={{
											height: '40px',
											width: '120px',
											justifyContent: 'center',
											alignItems: 'center',
											cursor: 'pointer',
											backgroundColor: getColor('Success +1'),
											borderRadius: '8px',
										}}
									>
										<LencoTypography
											sx={{
												...getStyle('Small_Medium'),
												color: '#FFFFFF',
											}}
										>
											Code copied !
										</LencoTypography>
									</FlexBox>
								)}
        </Box>
    );
}

export default CopyCode;