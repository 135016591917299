declare const ENV: any;

export const RentalRouteHelper = (options: { cityId: number; }) => {
  const cityConfig = GetRentalCityName(options);
  if (cityConfig && cityConfig.name) {
    return `/${cityConfig.name}`;
  } else {
    return '/rent';
  }
};

export const GetRentalCityName = ({ cityId }: { cityId: number; }) => {
  const cityConfig = (ENV as any) && Object.values((ENV as any).CITIES).find((x: any) => x.id == cityId);
  return cityConfig;
};