import { Box, Typography } from '@mui/material';
import ChronometerIcon from '@/assets/ChronometerIcon';
import { useEffect, useState } from 'react';
import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { pluralizeStringWithQuantity } from '@/utils/stringTransformations';

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;

type TimeCountdownProps = {
	endDate: string;
	setOfferTimeOver: Function;
};

const offerExpiryTimerMApper = {
	zeroDays: {
		color: 'Gray -1',
		backgroundColor: 'Gray -4',
	},
	moreThan2Days: {
		color: 'Yellow +4',
		backgroundColor: 'Yellow -2',
	},
	lessThan2Days: {
		color: 'Warning +2',
		backgroundColor: 'Warning -2',
	},
	lessThan1Day: {
		color: 'Danger +2',
		backgroundColor: 'Danger -3',
	},
};

function getHumanReadableETA(timeGiven: Date) {
	const currentDate: Date = new Date();
	const remainingTime = {
		daysLeft: 0,
		timeLeftDisplayString: '',
		hoursLeft: 0,
	};

	const yearDiff = Math.abs(
		timeGiven.getFullYear() - currentDate.getFullYear()
	);

	const secondsDifference =
		(timeGiven.getTime() - currentDate.getTime()) / 1000;

	if (secondsDifference < 1) {
		remainingTime['daysLeft'] = 0;
		remainingTime['hoursLeft'] = 0;
		remainingTime['timeLeftDisplayString'] = '0 day';

		return remainingTime;
	}

	const daysLeft = Math.floor(secondsDifference / SECONDS_IN_DAY);
	remainingTime['daysLeft'] = daysLeft;

	if (yearDiff <= 1) {
		if (daysLeft < 2) {
			let hhLeft = Math.floor(secondsDifference / SECONDS_IN_HOUR);

			let ssLeft = Math.floor(
				(secondsDifference - hhLeft * SECONDS_IN_HOUR) % SECONDS_IN_MINUTE
			);

			let mmLeft =
				Math.floor(
					(secondsDifference - ssLeft - hhLeft * SECONDS_IN_HOUR) /
						SECONDS_IN_MINUTE
				) % 60;

			let timeLeftDisplayString = getTimeLeftDisplayString(
				hhLeft,
				mmLeft,
				ssLeft
			);
			remainingTime['timeLeftDisplayString'] = timeLeftDisplayString;
			remainingTime['hoursLeft'] = hhLeft;
		} else {
			remainingTime['timeLeftDisplayString'] = pluralizeStringWithQuantity(
				daysLeft,
				'day'
			);
		}
	} else {
		remainingTime['timeLeftDisplayString'] = pluralizeStringWithQuantity(
			daysLeft,
			'day'
		);
	}

	return remainingTime;
}

const getHourDisplayString = (hhLeft: number = 0) => {
	if (hhLeft >= 1 && hhLeft < 10) {
		return `0${hhLeft}h:`;
	} else if (hhLeft >= 10) {
		return `${hhLeft}h:`;
	} else {
		return '';
	}
};

const getSecondDisplayString = (ssLeft: number = 0) => {
	if (ssLeft >= 0 && ssLeft < 10) {
		return `0${ssLeft}s`;
	} else {
		return `${ssLeft}s`;
	}
};

const getMinuteDisplayString = (mmLeft: number = 0) => {
	if (mmLeft >= 0 && mmLeft < 10) {
		return `0${mmLeft}m:`;
	} else {
		return `${mmLeft}m:`;
	}
};

const getTimeLeftDisplayString = (
	hhLeft: number,
	mmLeft: number,
	ssLeft: number
) => {
	return (
		getHourDisplayString(hhLeft) +
		getMinuteDisplayString(mmLeft) +
		getSecondDisplayString(ssLeft)
	);
};

const TimeCountdown = (props: TimeCountdownProps) => {
	const [remainingTimeObj, setRemainingTimeObj] = useState({
		daysLeft: 0,
		timeLeftDisplayString: '',
		hoursLeft: 0,
	});

	const [customInterval, setCustomInterval] = useState<NodeJS.Timer>();

	const getOfferTimerStyle = () => {
		const daysLeft = remainingTimeObj['daysLeft'];
		const hoursLeft = remainingTimeObj['hoursLeft'];
		const timeLeftDisplayString = remainingTimeObj['timeLeftDisplayString'];

		if (timeLeftDisplayString == '0 day') {
			return offerExpiryTimerMApper['zeroDays'];
		}

		if (daysLeft >= 2) {
			return offerExpiryTimerMApper['moreThan2Days'];
		} else if (hoursLeft > 24 && hoursLeft < 48) {
			return offerExpiryTimerMApper['lessThan2Days'];
		} else {
			return offerExpiryTimerMApper['lessThan1Day'];
		}
	};

	const offerTimerStyle = getOfferTimerStyle();

	useEffect(() => {
		if (props.endDate != null) {
			const timeGiven = new Date(props.endDate);

			if (!customInterval) {
				setCustomInterval(
					setInterval(() => {
						const d = getHumanReadableETA(timeGiven);
						setRemainingTimeObj(d);
						if (d.timeLeftDisplayString == '00s') {
							props.setOfferTimeOver(true);
						}
					}, 1000)
				);
			}
			return () => {
				clearInterval(customInterval);
				setCustomInterval(undefined);
			};
		}
	}, []);

	if (!remainingTimeObj['timeLeftDisplayString']) return null;

	return (
		<FlexBox height='0px' alignItems='center'>
			<FlexBox
				width='88px'
				alignItems='center'
				justifyContent='center'
				sx={{
					p: '4px 0px',
					background: getColor(`${offerTimerStyle.backgroundColor}`),
					zIndex: 2,
					borderRadius: '100px 0px 0px 100px',
				}}
			>
				<Typography
					lineHeight={1}
					sx={{
						...getStyle('Tiny_Medium'),
						color: getColor(`${offerTimerStyle.color}`),
					}}
				>
					{remainingTimeObj['timeLeftDisplayString']}
				</Typography>
			</FlexBox>
			<Box sx={{ zIndex: 2, position: 'relative', left: '-8px', top: '2px' }}>
				<ChronometerIcon fill={getColor(`${offerTimerStyle.color}`)} />
			</Box>
		</FlexBox>
	);
};

export default TimeCountdown;
