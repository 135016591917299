import { LencoImage } from '@/atomic-components/Image';
import { IImageTile1 } from './IImageTile1';
import { Box } from '@mui/material';
import Link from 'next/link';
import { reduceLinkToUsableForm } from '@/utils/links';

export default function ImageTile1(props: IImageTile1) {
	const { data } = props;

	const ImageTile1Reducer = () => {
		return {
			image: data.image,
			placeHolderText: data?.placeHolderText,
		};
	};
	const ImageTile1Data = ImageTile1Reducer();

	return (
		<Link
			href={reduceLinkToUsableForm(
				props?.cta?.data?.actionData?.deeplinkPath || '#'
			)}
		>
			<Box sx={{ width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
				<LencoImage
					lazy={true}
					aspectRatio={ImageTile1Data.image.aspectRatio}
					url={ImageTile1Data.image.url}
					alt={ImageTile1Data.placeHolderText || ImageTile1Data.image.url}
				/>
			</Box>
		</Link>
	);
}
