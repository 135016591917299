import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import { ICarousel } from './model';
import { EffectCoverflow, Lazy, Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getColor } from '@/utils/theme/colors';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import SwiperButton from '@/assets/swiper/LencoSwiperButtons.svg';
import SwiperButtonHoverable from '@/assets/swiper/LencoSwiperButtonHover.svg';

import Hoverable from '@/atomic-components/Hoverable';

import 'swiper/css/effect-coverflow';

function SpotlightCarousel(props: ICarousel) {
	// const CustomDiv = styled(Box)`
	// padding:0 24px;
	// max-width:1440px;
	// width:100%;
	// position:relative;
	// overflow: hidden;
	// min-height:500px !important;
	// .SpotLightSwiper .swiper{
	//     position:relative;
	//     overflow-x: visible;
	//     height: 100%;
	// }
	// .SpotLightSwiper .swiper-pagination {
	//     text-align:center;
	//     padding-top:8px;
	// }
	// .swiper-wrapper{
	//     align-items:center;
	//     height: 100%;

	// }
	// .SpotLightSwiper .swiper-wrapper{
	//     padding-bottom:30px;

	// }

	// .SpotLightSwiper .swiper-slide{
	//     border-radius:8px;
	//     overflow:hidden;
	//     width:100%;
	//     box-shadow: 0px 8px 16px rgba(34, 34, 34, 0.08);
	// }

	// .SpotLightSwiper .swiper-slide-active {

	//     border-radius:8px;

	//     transition:all 03s ease;

	//     transform: scale(1.5);
	//     // width:310px !important;
	//     &::after{
	//         width: 100%;
	//         content: '';
	//         position: absolute;
	//         top: 0px;
	//         height: 100%;
	//     }

	//   }

	// .SpotLightSwiper .swiper-pagination-bullet {
	//     width: 10px;
	//     height: 10px;
	//     display: inline-block;
	//     background: ${getColor('Gray 0')};
	//     opacity: .1;
	//     margin: 0 5px;
	//     -webkit-border-radius: 20px;
	//             border-radius: 20px;
	//     -webkit-transition: opacity .5s, background-color .5s, width .5s;
	//     -o-transition: opacity .5s, background-color .5s, width .5s;
	//     transition: opacity .5s, background-color .5s, width .5s;
	//     -webkit-transition-delay: .5s, .5s, 0s;
	//          -o-transition-delay: .5s, .5s, 0s;
	//             transition-delay: .5s, .5s, 0s;
	//     }
	// }
	// .SpotLightSwiper .swiper-pagination-clickable .swiper-pagination-bullet {
	//     cursor: pointer;
	// }
	// .SpotLightSwiper .swiper-pagination .swiper-pagination-bullet-active {
	//     opacity:0.3;
	//     background: ${getColor('Gray 0')};
	//     width: 30px;
	//     -webkit-transition-delay: 0s;
	//          -o-transition-delay: 0s;
	//             transition-delay: 0s;
	// }

	// `;
	const MeraSwiper = styled(Swiper)`
    height: 450px;
	@keyframes anim {
		0% { transform: scaleX(1.14); box-shadow: none }
		100% { transform: scaleX(1.14) scaleY(1.14); box-shadow: 0px -1px 19px -3px rgba(102,102,102,1);}
	  }
	.swiper-pagination {
		bottom: 0px !important;
	}
    .swiper-slide {
        height: 410px;
        align-items: end;
        display: flex;
        border-radius: 8px;
        overflow: hidden;
		align-self: flex-end;
		padding-bottom: 25px;
        .component-wrapper {
            border-radius: 8px;
            overflow: hidden;
        }
        &.swiper-slide-active {
            margin-right: 40px !important;
            margin-left: 20px;
            overflow: visible;
            .component-wrapper {
				animation: anim 500ms;
				transform-origin:bottom center;
				animation-fill-mode: forwards;
            }
			align-self: flex-end;
        }
    }
     .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            display: inline-block;
            background: ${getColor('Gray 0')};
            opacity: .1;
            margin: 0 5px;
            -webkit-border-radius: 20px;
                    border-radius: 20px;
            -webkit-transition: opacity .5s, background-color .5s, width .5s;
            -o-transition: opacity .5s, background-color .5s, width .5s;
            transition: opacity .5s, background-color .5s, width .5s;
            -webkit-transition-delay: .5s, .5s, 0s;
                 -o-transition-delay: .5s, .5s, 0s;
                    transition-delay: .5s, .5s, 0s;
            }        
        }
.swiper-pagination-clickable .swiper-pagination-bullet {
            cursor: pointer;
        }
.swiper-pagination .swiper-pagination-bullet-active {
            opacity:0.3;
            background: ${getColor('Gray 0')};
            width: 30px;
            -webkit-transition-delay: 0s;
                 -o-transition-delay: 0s;
                    transition-delay: 0s;
        }  
    `;
	const swiperRef = React.useRef<SwiperRef>(null);
	const prevRef = React.useRef(null);
	const nextRef = React.useRef(null);
	return (
		<div style={{ position: 'relative' }}>
			{swiperRef && (
				<Box
				onClick={() => swiperRef?.current?.swiper?.slidePrev()}
					sx={{
						transform: 'rotate(180deg) translate(-50%)',
						position: 'absolute',
						zIndex: 9,
						top: '40%',
						left: '-50px',
						cursor: 'pointer',
						':hover': {},
					}}
				>
					<Hoverable
						onClick={() => {}}
						icons={{
							before: <SwiperButton />,
							after: <SwiperButtonHoverable />,
							clicked: <SwiperButtonHoverable />,
						}}
					/>
				</Box>
			)}
			<MeraSwiper
				ref={swiperRef}
				modules={[Navigation, Pagination]}
				className='SpotLightSwiper'
				loop={true}
				speed={700}
				spaceBetween={20}
				slidesPerView={props.slidesPerView}
				pagination={true}
				navigation={{
					prevEl: prevRef.current,
					nextEl: nextRef.current,
				}}
				centeredSlides={props.centered}
			>
				{props?.children?.map((d) => {
					return (
						// <Box key={d.key} sx={{borderRadius:'8px',height:'100%',width:'100%',overflow:'hidden'}}>

						<SwiperSlide key={d.key}>
							<Box
								key={d.key}
								sx={{ width: '100%' }}
								className='component-wrapper'
							>
								{d}
							</Box>
						</SwiperSlide>
						// </Box>
					);
				})}
			</MeraSwiper>
			{swiperRef && (
				<Box
					onClick={() => swiperRef?.current?.swiper?.slideNext()}

					id='nextButton'
					sx={{ position: 'absolute', zIndex: 9, top: '40%', right: '-25px' }}
				>
					<Hoverable
						onClick={() => {}}
						icons={{
							before: <SwiperButton />,
							after: <SwiperButtonHoverable />,
							clicked: <SwiperButtonHoverable />,
						}}
					/>
				</Box>
			)}
		</div>
	);
}

export default SpotlightCarousel;
