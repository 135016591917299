import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { IOfferData } from './model';
import OfferPercentage from '@/assets/OfferPercentage.svg';
import DiscountBadge from '@/assets/discount-badge.svg';
import FlexBox from '@/atomic-components/FlexBox';
import { getStyle } from '@/utils/theme/fonts';
import { getColor } from '@/utils/theme/colors';
import TimeCountdown from '../TimeCoundown';

function OfferTile(props: IOfferData) {
	const { data } = props;
	const OfferTileData = () => {
		return {
			title: data?.header,
			subtitle: data?.description,
			couponcode: data?.code,
		};
	};

	const OfferData = OfferTileData();
	return (
		<Box
			sx={{
				m: '12px 0px',
				':before': {
					content: '""',
					width: '16px',
					height: '16px',
					position: 'absolute',
					left: 0,
					top: '50%',
					transform: 'translate(-50%,-50%)',
					background: 'white',
					borderRadius: '50%',
				},
				':after': {
					content: '""',
					width: '16px',
					height: '16px',
					position: 'absolute',
					right: -16,
					top: '50%',
					transform: 'translate(-50%,-50%)',
					background: 'white',
					borderRadius: '50%',
				},
			}}
		>
			<Box sx={{ position: 'absolute', right: 0, zIndex: 2 }}>
				<OfferPercentage />
			</Box>
			<FlexBox fullWidth justifyContent='flex-end'>
				<Box sx={{ zIndex: 2, position: 'relative' }}>
					{data.endDate && (
						<TimeCountdown
							endDate={data.endDate as string}
							setOfferTimeOver={() => null}
						/>
					)}
				</Box>
			</FlexBox>
			<FlexBox
				fullWidth
				justifyContent={'start'}
				sx={{
					p: '24px 8px 24px 24px',
					background: 'linear-gradient(270deg, #FFFBDB 0%, #FFF5B7 100%)',
				}}
				id='offer-main'
			>
				<Box sx={{ mr: '8px' }}>
					<DiscountBadge />
				</Box>
				<Box sx={{ zIndex: 3 }}>
					<LencoTypography
						sx={{
							...getStyle('H10A_SemiBold'),
							color: getColor('Gray 0'),
							WebkitBoxOrient: 'vertical',
							display: '-webkit-box',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							WebkitLineClamp: 1,
							wordBreak: 'break-word',
							whiteSpace: 'initial',
						}}
					>
						{OfferData?.title}
					</LencoTypography>
					<LencoTypography
						sx={{
							...getStyle('Small_Medium'),
							color: getColor('Gray -2'),
							display: '-webkit-box',
							WebkitLineClamp: 3,
							height: '48px',
							overflow: 'hidden',
							WebkitBoxOrient: 'vertical',
						}}
					>
						{OfferData?.subtitle}
					</LencoTypography>
					<LencoTypography
						sx={{
							...getStyle('Small_SemiBold'),
							color: getColor('Gray -2'),
							WebkitBoxOrient: 'vertical',
							display: '-webkit-box',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							WebkitLineClamp: 1,
							wordBreak: 'break-word',
							whiteSpace: 'initial',
						}}
					>
						Use Coupon Code : {OfferData?.couponcode}
					</LencoTypography>
				</Box>
			</FlexBox>
		</Box>
	);
}

export default OfferTile;
