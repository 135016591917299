export function pluralizeString(quantity: number, text: string, extension: string = 's') {
  if (quantity > 1) {
    return `${text}${extension}`;
  } else {
    return text;
  }
}

export function pluralizeStringWithQuantity(quantity: number, text: string, extension: string = 's') {
  const pluralizedText = pluralizeString(quantity, text, extension);

  return `${quantity} ${pluralizedText}`;
}