import { BasicCarousel } from '@/atomic-components/carousels/wrappers/basic-carousel';
import BuyRentWidget from '@/components/buyRent';
import FAQ from '@/components/unlmtd/FAQ/FAQ';
import BannerWidget from '@/widgets/BannerWidget';
import CarouselWithFiveTiles from '@/widgets/CarouselWithFiveTiles';
import CategoryGrid from '@/widgets/CategoryGrid';
import CarouselWithFourTiles from '@/widgets/FourTileinCarousel';
import SingleTileCarousel from '@/widgets/SingleTileCarousel';
import React from 'react';
import PlanSelector from '@/components/unlmtd/PlanSelector';

const widgets = {
	BUY_RENT: {
		component: BuyRentWidget,
	},
	HORIZONTAL_LIST_1: {
		component: CarouselWithFourTiles,
	},
	BANNER: {
		component: BannerWidget,
	},
	CATEGORY_GRID: {
		component: CategoryGrid,
	},
	GRID_WIDGET_2: {
		component: React.Fragment,
	},
	CAROUSEL_WIDGET_3: {
		component: CarouselWithFourTiles,
	},
	CAROUSEL_1: {
		component: SingleTileCarousel,
	},
	CAROUSEL_2: {
		component: CarouselWithFiveTiles,
	},
	GRADIENT_RADIAL: {
		component: CarouselWithFiveTiles,
	},
	//   ABOUT_WIDGET: {
	//     component: LencoGlobalFooter,
	//   },
	//   INJECTION_WIDGET_1: {
	//     component: InjectionWidget
	//   },
	OPTION_WIDGET: {
		component: CategoryGrid,
	},
	FAQ_LIST: {
		component: FAQ,
	},
	UL_Plan_Cards_1: {
		component: PlanSelector,
	},
};

export const getWidget = (widgetName: string): { component: any } => {
	try {
		return widgets[widgetName as keyof typeof widgets];
	} catch (error) {
		return { component: React.Fragment };
	}
};
