import FlexBox from '@/atomic-components/FlexBox';
import { componentMapper } from '@/utils/componentMapper';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';

function CategoryGrid(props: any) {
	const { components } = props.data;
	// const isRental = props?.data?.template?.data?.labels && props?.data?.template?.data?.labels.toLowerCase().includes('rent');
	const htmlTag = props.data.template?.data?.labels
		.toLocaleLowerCase()
		.includes('rent')
		? 'h2'
		: 'h1';

	return (
		<Box sx={{ width: '100%' }}>
			<LencoTypography
				component={htmlTag}
				sx={{
					...getStyle('H2_Medium'),
					color: getColor('Gray 0'),
					textAlign: 'center',
					paddingBottom: '16px',
				}}
			>
				{props?.data?.template?.data?.labels}
			</LencoTypography>
			<FlexBox
			className='category-grid'
				justifyContent={'center'}
				sx={{
					gap: '32px 64px',
				}}
				alignItems='center'
				width='100%'
				wrap='wrap'
			>
				{components?.map((component: any, index: number) => {
					const DynamicComponent = componentMapper(component);
					return (
						<Box
							key={`${DynamicComponent.key}-${index}`}
						>
							<DynamicComponent.component {...component} />
						</Box>
					);
				})}
			</FlexBox>
		</Box>
	);
}

export default CategoryGrid;
