import React, { useEffect, useState } from 'react';
import FlexBox from '@/atomic-components/FlexBox';
import PlanSelectorTile from './PlanSelectorTile';
import usePlan from '@/hooks/unlmtd/usePlan';
import { Box, Modal, Backdrop, Fade, Typography } from '@mui/material';
import { Plan } from '@/store/unlmtd/models/plan';
import { ApiState } from '@/models/generics';
import { Skeleton } from '@mui/material';
import { getStyle } from '@/utils/theme/fonts';
import { getColor } from '@/utils/theme/colors';
import { PlanSelectorStatus } from '@/store/unlmtd/slices/planConfigSlice';
import { useLocation } from '@/hooks/useLocation';
import PlanShimmer from './PlanShimmer';

const PlanSelector = ({ open, onClose , source}: any) => {
	const { plans, selectedPlanId, planActions, planSelectorStatus } = usePlan();
	const [modalOpen, setModalOpen] = useState(true);

	const { selectedLocation } = useLocation();
	useEffect(() => {
		setModalOpen(true);
		if (selectedLocation?.pincode !== undefined) {
			planActions.getPlans();
		}
	}, [selectedLocation]);

	const handleCloseModal = () => {};
	return (
		<>
			<Box
				sx={{
					width: 'auto',
					// maxWidth: '90vw',

					borderRadius: '8px',

					p: 4,
					overflowY: 'auto',
				}}
				onClick={(event) => event.stopPropagation()}
			>
				<Typography
					sx={{
						textAlign: 'center',
						...getStyle('H5A_Medium'),
						color: getColor('gray -5'),
						marginBottom: '2rem',
					}}
				>
					{/* Select a Plan to Proceed */}
				</Typography>
				{planActions.getPlanApiState() !== ApiState.LOADING ? (
					<FlexBox
						gap='1.5rem'
						flexDirection='column'
						alignItems='center'
						justifyContent={plans.length >5 ? 'start': 'center'}
						sx={{ flexWrap: 'nowrap' }}
					>
						{(plans || []).map((plan: Plan) => (
							<PlanSelectorTile
								key={plan.id}
								data={plan}
								isSelected={parseInt(selectedPlanId) === plan.id}
								source={source}
							/>
						))}
					</FlexBox>
				) : (
					<FlexBox
						width='100%'
						height='400px'
						gap='2rem'
						justifyContent='center'
					>
						<Skeleton
							variant='rectangular'
							animation='wave'
							width='18%'
							height='400px'
						></Skeleton>
						<Skeleton
							variant='rectangular'
							animation='wave'
							width='18%'
							height='400px'
						></Skeleton>
						<Skeleton
							variant='rectangular'
							animation='wave'
							width='18%'
							height='400px'
						></Skeleton>
						<Skeleton
							variant='rectangular'
							animation='wave'
							width='18%'
							height='400px'
						></Skeleton>
						<Skeleton
							variant='rectangular'
							animation='wave'
							width='18%'
							height='400px'
						></Skeleton>
					</FlexBox>
				)}
			</Box>
		</>
	);
};

export default PlanSelector;
