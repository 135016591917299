import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';

import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import Link from 'next/link';
import { IWidgetHeader } from './model';
import { reduceLinkToUsableForm } from '@/utils/links';
import { Headers } from '@/services/layout-service/models/layout';

type DrawerData = {
	drawerData?: {
		setOpenDrawer: (openDrawer: boolean) => void;
		openDrawer: boolean;
	};
};

function WidgetHeader(props: Headers & DrawerData) {
	const deeplinkContainsOffer =
		props?.ctaContent?.cta?.data?.actionData?.deeplinkPath?.includes('offer');

	return (
		<Box sx={{ padding: '24px' }}>
			<FlexBox justifyContent={'space-between'} alignItems={'center'}>
				<FlexBox justifyContent={'start'}>
					{props?.icon && (
						<Box sx={{ width: '48px', margin: '0 4px' }}>
							<LencoImage
								url={props.icon.url}
								aspectRatio={props.icon.aspectRatio}
							/>
						</Box>
					)}
					<Box>
						{props?.textContent?.map((content) => {
							const typeScale = content[0]?.typeScale?.split('-').join('_');
							return (
								<LencoTypography
									key={content[0].text}
									variant={typeScale as any}
									color={getColor(content[0].color)}
								>
									{content[0]?.text}
								</LencoTypography>
							);
						})}
					</Box>
				</FlexBox>
				{props?.ctaContent && !deeplinkContainsOffer && (
					<Link
						href={reduceLinkToUsableForm(
							props?.ctaContent?.cta?.data?.actionData?.deeplinkPath
						)}
					>
						<FlexBox justifyContent={'start'} alignContent={'center'}>
							<LencoTypography
								variant={
									props?.ctaContent?.text?.typeScale.split('-').join('_') as any
								}
								color={props?.ctaContent?.text?.color}
							>
								{props?.ctaContent?.text?.text}
							</LencoTypography>
							<Box width={12} margin={'0 4px'}>
								{props?.ctaContent?.icon?.url && (
									<LencoImage
										url={props.ctaContent.icon.url}
										aspectRatio={props?.ctaContent?.icon?.aspectRatio}
									/>
								)}
							</Box>
						</FlexBox>
					</Link>
				)}
				{deeplinkContainsOffer && (
					<FlexBox
						justifyContent={'start'}
						alignContent={'center'}
						sx={{ cursor: 'pointer' }}
						onClick={() =>
							props?.drawerData?.setOpenDrawer(!props.drawerData?.openDrawer)
						}
					>
						<LencoTypography
							variant={
								props?.ctaContent?.text?.typeScale.split('-').join('_') as any
							}
							color={props?.ctaContent?.text?.color}
						>
							{props?.ctaContent?.text?.text}
						</LencoTypography>
						<Box width={12} margin={'0 4px'}>
							{props?.ctaContent?.icon?.url && (
								<LencoImage
									url={props.ctaContent.icon.url}
									aspectRatio={props?.ctaContent?.icon?.aspectRatio}
								/>
							)}
						</Box>
					</FlexBox>
				)}
			</FlexBox>
		</Box>
	);
}

export default WidgetHeader;
