import FlexBox from '@/atomic-components/FlexBox';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { LineOfProduct } from '@/utils/enums';
import { IProductTile2 } from './IProductTile2';
import Link from 'next/link';
import { PdpUrlHelper } from '@/utils/seo-url-helper';

export default function ProductTile2(props: IProductTile2 ) {
	const { data } = props;

	const ProductTile2Reducer = () => {
		return {
			available: data.available,
			delivery: data.delivery,
			id: data.id,
			lineOfProduct: data.lineOfProduct,
			name: data.name,
			permalink: data.permalink,
			pricing: data.pricing,
			thumbnail: data.thumbnail,
			title: data.title,
			variants: data.variants,
			vertical: data.vertical,
		};
	};

	const ProductTile2Data = ProductTile2Reducer();

	return (
		<Link href={`${PdpUrlHelper({lop:ProductTile2Data.lineOfProduct,permalink:ProductTile2Data.permalink})}`}>
		<Box
			id='ProductTile2'
			sx={{
				width: 300,
				position: 'relative',
				borderRadius: '8px',
			}}
		>
			<Box sx={{ width: '100%', marginBottom:'-50%' }}>
				<LencoImage
					alt={ProductTile2Data.name || ProductTile2Data.thumbnail.url}
					aspectRatio={ProductTile2Data.thumbnail.aspectRatio}
					url={ProductTile2Data.thumbnail.url}
				/>
			</Box>
			<Box sx={{background:getColor(''),paddingTop:'calc(40% - 24px)',boxShadow: '0px 8px 16px rgba(219, 185, 54, 0.3)',}}>
			<LencoTypography
				sx={{
					color: getColor('Gray 0'),
					paddingTop:'calc(25% - 24px)',
					marginBottom: '20px',
					marginX: '24px',
					WebkitLineClamp:2,
					display:'-webkit-box',
					overflow:'hidden',
					WebkitBoxOrient:'vertical',
					minHeight:'108px'
				}}
				variant='H11A_Medium'
			>
				{ProductTile2Data?.title || ProductTile2Data?.name}
			</LencoTypography>
			<Box
				sx={{
					background: getColor('Yellow 0'),
					position: 'relative',
					borderRadius: '0 0 8px 8px',
				}}
			>
				{LineOfProduct[
					ProductTile2Data.lineOfProduct as keyof typeof LineOfProduct
				].length > 0 && (
					<LencoTypography
						sx={{
							color: getColor('Gray -1'),
							padding: '6px 24px',
							display: 'inline-block',
							borderTopRightRadius: '16px',
							borderBottomRightRadius: '16px',
							background: getColor('Navy -4'),
							position: 'absolute',
							top: '-14px',
						}}
						variant='Small_Medium'
					>
						{
							LineOfProduct[
								ProductTile2Data.lineOfProduct as keyof typeof LineOfProduct
							]
						}
					</LencoTypography>
				)}
				<FlexBox
					sx={{
						alignItems: 'center',

						marginLeft: '24px',
						paddingBottom: '18px',
						paddingTop:
							LineOfProduct[
								ProductTile2Data.lineOfProduct as keyof typeof LineOfProduct
							].length > 0
								? '16px'
								: '8px',
					}}
				>
					<LencoTypography variant='H6_SemiBold' color={getColor('Gray 0')}>
						{ProductTile2Data.pricing.discountPercentage.displayValue}
					</LencoTypography>

					<LencoTypography
						sx={{ color: getColor('Gray 0'), marginLeft: '4px' }}
						variant='Small_SemiBold'
					>
						OFF
					</LencoTypography>

					<LencoTypography
						variant='H11_SemiBold'
						sx={{
							color: getColor('Gray -1'),
							marginLeft: '8px',
						}}
					>
						{ProductTile2Data?.pricing?.monthlyRental?.displayValue || ProductTile2Data?.pricing?.basePrice?.displayValue}
					</LencoTypography>
				</FlexBox>
			</Box>
			</Box>
		</Box>
		</Link>
	);
}
