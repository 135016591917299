import { BasicCarousel } from '@/atomic-components/carousels/wrappers/basic-carousel';
import WidgetFooter from '@/components/Footers/WidgetFooter';
import WidgetHeader from '@/components/Headers/WidgetHeader';
import OfferList from '@/components/ProductDisplayPage/Components/SideBar/Offers/offerList';
import { componentMapper } from '@/utils/componentMapper';
import { Box, Drawer } from '@mui/material';
import { useState } from 'react';
import LayoutOffer from './layoutOffer';
import { Widget } from '@/services/layout-service/models/layout';

interface CarouselWithFourTilesProps extends Widget {}

function CarouselWithFourTiles(props: CarouselWithFourTilesProps) {
	const { components } = props.data;
	const [openDrawer, setOpenDrawer] = useState(false);
	return (
		<Box>
			{props?.data?.headers && (
				<WidgetHeader
					{...props.data.headers}
					drawerData={{ setOpenDrawer, openDrawer }}
				/>
			)}
			<BasicCarousel
				spaceBetween={10}
				slidesPerView={
					props?.data?.template?.totalwidgets
						? props?.data?.template?.totalwidgets
						: 4
				}
				dots={false}
				loop={false}
				centered={false}
			>
				{components?.map((component: any, index: number) => {
					const DynamicComponent = componentMapper(component);
					return (
						<Box
							key={`${DynamicComponent.key}-${index}`}
							sx={{ paddingLeft: index == 0 ? '16px' : '0' }}
						>
							<DynamicComponent.component {...component} />
						</Box>
					);
				})}
			</BasicCarousel>
			{props?.data?.footer && <WidgetFooter {...props?.data?.footer} />}
			{props?.data?.headers?.ctaContent?.cta?.data?.actionData?.deeplinkPath?.includes(
				'offer'
			) && (
				<Drawer
					anchor='right'
					open={openDrawer}
					onClose={() => setOpenDrawer(!openDrawer)}
				>
					<LayoutOffer
						offerData={components}
						drawerData={{ setOpenDrawer, openDrawer }}
					/>
				</Drawer>
			)}
		</Box>
	);
}

export default CarouselWithFourTiles;
