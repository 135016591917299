import { LencoImage } from "@/atomic-components/Image";
import { Box, Typography } from "@mui/material";
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { ICategoryTile } from "./model";
import { getStyle } from "@/utils/theme/fonts";
import { getColor } from "@/utils/theme/colors";
import Link from "next/link";
import FlexBox from "@/atomic-components/FlexBox";
import { ListingUrlHelper } from "@/utils/seo-url-helper";

function CategoryTile(props:ICategoryTile) {
    const {data}=props
    const ListingUrlData={
        collectionType:props.data.collectionType,
        name:props.data.name
    }
    return ( 
        <Link href={ListingUrlHelper(ListingUrlData) || '#'}>
            <FlexBox direction="column" sx={{justifyContent:'center',alignItems:'center'}}>
                <Box sx={{width:'100px'}}>
                    <LencoImage url={data?.image?.url} aspectRatio={data?.image?.aspectRatio}/>
                </Box>
                <Typography sx={{...getStyle('P_Regular'),color:getColor('Gray -1')}}>
                    {data?.label}
                </Typography>
            </FlexBox>
        </Link>
     );
}

export default CategoryTile;