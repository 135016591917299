import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';

export const productTile1Style = {
	vertical: {
		color: getColor('Gray -1'),
		padding: '6px 24px',
		display:'inline-block',
		marginBottom: '8px',
		left:0,
		borderTopRightRadius: '16px',
		borderBottomRightRadius: '16px',
		background: getColor('Minty -4'),
	},
};
