import React, { useState } from 'react';
import {
	Box,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Button,
	useTheme,
} from '@mui/material';
import styles from './faq.module.css';
import { FaqData } from './modal';
import WidgetHeader from '@/components/Headers/WidgetHeader';
import WidgetFooter from '@/components/Footers/WidgetFooter';
import FaqComponents from './FaqComponents';
const FAQ = ({ data, widgetName, id, footer }: FaqData) => {
	return (
		<Box className={styles.faqContainer}>
			{/* {data?.headers && <WidgetHeader {...data.headers} />} */}
			{data?.components && (
				<FaqComponents data={data} id={id} widgetName={widgetName} />
			)}
			{footer && <WidgetFooter {...footer} />}
		</Box>
	);
};

export default FAQ;
