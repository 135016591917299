type TSVGProps = {
	fill?: string;
};

const ChronometerIcon = (props: TSVGProps) => {
	return (
		<svg
			width='22'
			height='23'
			viewBox='0 0 22 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.44293 4.36594L5.15834 3.07868C4.86035 2.78009 4.46396 2.61569 4.04214 2.61569C3.62032 2.61569 3.22393 2.78013 2.92598 3.07868L1.99481 4.01188C1.38186 4.62603 1.38186 5.6254 1.99481 6.23959L3.28211 7.52956C3.40801 7.65571 3.57314 7.71879 3.73835 7.71879C3.90305 7.71879 4.06783 7.65601 4.19365 7.5305C4.44562 7.27905 6.19041 5.52854 6.44203 5.27743C6.69396 5.02602 6.69439 4.61795 6.44293 4.36594Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M13.1346 0H8.86538C8.38746 0 8 0.447733 8 1C8 1.55227 8.38746 2 8.86538 2H13.1346C13.6125 2 14 1.55227 14 1C14 0.447733 13.6125 0 13.1346 0Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M18.0716 4.92941C16.1828 3.04037 13.6714 2 11 2C8.32864 2 5.81724 3.04037 3.92845 4.92941C2.04 6.81809 1 9.32918 1 12C1 14.6708 2.04 17.1819 3.92845 19.0706C5.81724 20.9596 8.32864 22 11 22C13.6714 22 16.1828 20.9596 18.0716 19.0706C19.96 17.1819 21 14.6708 21 12C21 9.32918 19.96 6.81809 18.0716 4.92941Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M11 4.5C6.8645 4.5 3.5 7.86447 3.5 12C3.5 16.1355 6.8645 19.5 11 19.5C15.1355 19.5 18.5 16.1355 18.5 12C18.5 7.86447 15.1355 4.5 11 4.5Z'
				fill='white'
			/>
			<path
				d='M11 6.49787C11.5523 6.49787 12 6.05013 12 5.49787V4.044C11.6703 4.01547 11.3369 4 11 4C10.6631 4 10.3297 4.01547 10 4.044V5.49787C10 6.05013 10.4477 6.49787 11 6.49787Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M11 17.4979C10.4477 17.4979 10 17.9456 10 18.4979V19.9517C10.3297 19.9803 10.6631 19.9957 11 19.9957C11.3369 19.9957 11.6703 19.9803 12 19.9517V18.4979C12 17.9456 11.5523 17.4979 11 17.4979Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M5.5 11.9979C5.5 11.4456 5.05227 10.9979 4.5 10.9979H3.04614C3.0176 11.3275 3.00214 11.6609 3.00214 11.9979C3.00214 12.3348 3.0176 12.6682 3.04614 12.9979H4.5C5.05227 12.9979 5.5 12.5501 5.5 11.9979Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M16.5 11.9979C16.5 12.5501 16.9477 12.9979 17.5 12.9979H18.9539C18.9824 12.6682 18.9979 12.3348 18.9979 11.9979C18.9979 11.6609 18.9824 11.3275 18.9539 10.9979H17.5C16.9477 10.9979 16.5 11.4456 16.5 11.9979Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M11 8C10.4477 8 10 8.44773 10 9V10V10.4539V12C10 12.5523 10.4477 13 11 13H11.544H12.4539H12.9979C13.5501 13 13.9979 12.5523 13.9979 12C13.9979 11.4477 13.5501 11 12.9979 11H12.4539H12L12 10.4539L12 10L12 9C12 8.44773 11.5523 8 11 8Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M18.1805 21.7663C18.571 21.3757 18.571 20.7426 18.1805 20.352L16.4453 18.6169C16.192 18.8298 15.9454 19.0546 15.7071 19.2929C15.4689 19.5311 15.244 19.7778 15.0311 20.0311L16.7663 21.7663C17.1568 22.1568 17.79 22.1568 18.1805 21.7663Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
			<path
				d='M3.23374 21.7663C3.62425 22.1568 4.25744 22.1568 4.64795 21.7663L6.3831 20.0311C6.17017 19.7778 5.94535 19.5311 5.70711 19.2929C5.46886 19.0546 5.22217 18.8298 4.96889 18.6169L3.23374 20.352C2.84323 20.7426 2.84323 21.3757 3.23374 21.7663Z'
				fill={props?.fill ? props?.fill : '#555555'}
			/>
		</svg>
	);
};

export default ChronometerIcon;
