import { getColor } from '@/utils/theme/colors';
import DiscountBadgeIcon from '@/assets/offers/DiscountBadgeIcon';
import FlexBox from '@/atomic-components/FlexBox';
import { Box } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { getStyle } from '@/utils/theme/fonts';
import CrossIcon from '@/assets/pdp/crossIcon.svg';
import { useState } from 'react';
import CopyCode from './copyCode';
import TimeCountdown from '@/components/TimeCoundown';

function LayoutOffer(props: any) {
	return (
		<Box sx={{ width: '443px', background: getColor(''), pt: '32px' }}>
			<FlexBox
				sx={{
					width: '100%',
					padding: '16px 32px',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<LencoTypography
					componentUsage='STANDALONE'
					sx={{
						...getStyle('H11_Medium'),
						color: getColor('Gray 0'),
						margin: '16px 0 ',
					}}
				>
					Offers and Discounts
				</LencoTypography>
				<Box
					sx={{ cursor: 'pointer' }}
					onClick={() =>
						props.drawerData.setOpenDrawer(!props?.drawerData?.openDrawer)
					}
				>
					<CrossIcon />
				</Box>
			</FlexBox>
			{/* <LencoTypography componentUsage="STANDALONE" sx={{...getStyle('H14_Regular'),color:getColor('Gray 0'),margin:'16px 40px '}}>Offers for you</LencoTypography> */}
			{props?.offerData?.map((d: any) => {
				return (
					<Box key={d?.data?.header}>
						<Box
							sx={{
								background:
									'linear-gradient(270deg, #FFF5B7 0%, #FFFBDB 91.58%);',
								margin: '16px 40px',
								borderRadius: '4px',
							}}
						>
							<FlexBox justifyContent='flex-end'>
								<Box sx={{ zIndex: 2, position: 'relative' }}>
									<TimeCountdown
										endDate={d.data?.endDate}
										setOfferTimeOver={() => null}
									/>
								</Box>
							</FlexBox>
							<Box p='16px' sx={{ borderBottom: '1px dashed #FFE878' }}>
								<FlexBox fullWidth justifyContent={'space-between'}>
									<FlexBox fullWidth justifyContent={'flex-start'}>
										<DiscountBadgeIcon height={48} width={48} />
										<Box ml='8px'>
											<LencoTypography
												variant='H11A_SemiBold'
												sx={{ fontSize: 20 }}
											>
												{d?.data?.header}
											</LencoTypography>
											<LencoTypography
												variant='Small_Regular'
												color={getColor('Gray -2')}
												sx={{
													width: '90%',
													WebkitBoxOrient: 'vertical',
													display: '-webkit-box',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													WebkitLineClamp: 2,
													wordBreak: 'break-word',
													whiteSpace: 'initial',
												}}
											>
												{d?.data?.description}
											</LencoTypography>
											{d?.data?.expiryText?.length != 0 && (
												<LencoTypography
													variant='Small_Regular'
													color={getColor('Danger +3')}
													mt='8px'
												>
													{d?.data?.expiryText}
												</LencoTypography>
											)}
										</Box>
									</FlexBox>
								</FlexBox>
								<FlexBox></FlexBox>
							</Box>
							<FlexBox
								p='12px 16px'
								height='54px'
								justifyContent={'space-between'}
								alignItems='center'
							>
								<LencoTypography variant='Small_Medium' color='#937517'>
									Coupon code : {d?.data?.code}
								</LencoTypography>
								<CopyCode code={d?.data?.code} />
							</FlexBox>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
}

export default LayoutOffer;
