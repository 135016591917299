import { BasicCarousel } from '@/atomic-components/carousels/wrappers/basic-carousel';
import WidgetFooter from '@/components/Footers/WidgetFooter';
import WidgetHeader from '@/components/Headers/WidgetHeader';
import { componentMapper } from '@/utils/componentMapper';
import { Box } from '@mui/material';

function SingleTileCarousel(props: any) {
	const { components } = props.data;

	return (
		<Box>
			{props?.data?.headers && <WidgetHeader {...props.data.headers} />}
			<BasicCarousel
				spaceBetween={10}
				slidesPerView={1}
				dots={false}
				centered={false}
			>
				{components?.map((component: any, index: number) => {
					const DynamicComponent = componentMapper(component);
					return (
						<Box
							key={`${DynamicComponent.key}-${index}`}
							sx={{ paddingLeft: index == 0 ? '16px' : '0' }}
						>
							<DynamicComponent.component {...component} />
						</Box>
					);
				})}
			</BasicCarousel>
			{props?.data?.footer && <WidgetFooter {...props?.data?.footer}/>}  

		</Box>
	);
}

export default SingleTileCarousel;
