import FlexBox from '@/atomic-components/FlexBox';
import { IfooterData } from './model';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { getStyle } from '@/utils/theme/fonts';
import { getColor } from '@/utils/theme/colors';
import { LencoImage } from '@/atomic-components/Image';
import { Box } from '@mui/material';
import Link from 'next/link';
import { reduceLinkToUsableForm } from '@/utils/links';
import { CtaContent, Footer } from '@/services/layout-service/models/layout';

interface WidgetFooterProps extends Footer {}

function WidgetFooter(props: WidgetFooterProps) {
	try {
		return (
			<Link
				href={reduceLinkToUsableForm(
					props?.ctaContent?.cta?.data?.actionData?.deeplinkPath
				)}
			>
				<FlexBox
					sx={{ justifyContent: 'start', pl: '24px', alignItems: 'flex-end' }}
				>
					<LencoTypography
						componentUsage='STANDALONE'
						sx={{
							...getStyle(
								props?.ctaContent?.text?.typeScale?.split('-')?.join('_')
							),
							color: getColor(props?.ctaContent?.text?.color),
							mr: '4px',
						}}
					>
						{props?.ctaContent?.text?.text}
					</LencoTypography>
					{props?.ctaContent?.icon && (
						<Box sx={{ width: '16px' }}>
							<LencoImage
								url={props?.ctaContent?.icon?.url}
								aspectRatio={props?.ctaContent?.icon?.aspectRatio}
							/>
						</Box>
					)}
				</FlexBox>
			</Link>
		);
	} catch (error) {
		return null;
	}
}

export default WidgetFooter;
