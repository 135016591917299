import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { Box } from '@mui/system';
import Link from 'next/link';
import { RentalRouteHelper } from '@/utils/routes';
import { useLocation } from '@/hooks/useLocation';
import RightArrowIcon from '@/assets/RightArrowIcon';

type IBuyRentWidgetData = {
	data: Data;
	id: number;
	widgetName: string;
};

type Data = {
	template: Template;
	textItems: TextItems;
};

type Template = {
	type: string;
};

type TextItems = {
	buy: Content;
	rent: Content;
	unlmtd: Content;
};

type Content = {
	subtitle: Title;
	title: Title;
};

type Title = {
	color: string;
	text: string;
	typeScale: string;
};

function BuyRentWidget(props: IBuyRentWidgetData) {
	const { data } = props;
	const { selectedLocation } = useLocation();

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				marginTop: '-88px',
				zIndex: 2,
			}}
		>
			<FlexBox fullWidth justifyContent='center'>
				<FlexBox
					sx={{
						borderRadius: '8px',
						overflow: 'hidden',
						border: `solid 1px white`,
					}}
				>
					<Link href='/buy'>
						<Box
							sx={{
								background: getColor('Navy 0'),
								padding: '16px 32px 16px 40px',
								cursor: 'pointer',
								':hover': {
									background: getColor('Navy +2'),
								},
							}}
						>
							<FlexBox justifyContent='center' alignItems={'center'}>
								<LencoTypography variant='H7_Medium' color={getColor('')}>
									{data?.textItems?.buy?.subtitle?.text}
								</LencoTypography>
								<Box ml='8px'>
									<RightArrowIcon />
								</Box>
							</FlexBox>
						</Box>
					</Link>

					<Link
						href={`${RentalRouteHelper({
							cityId: selectedLocation.cityId as number,
						})}`}
					>
						<Box
							sx={{
								background: getColor('Orange 0'),
								padding: '16px 32px 16px 40px',
								cursor: 'pointer',
								':hover': {
									background: getColor('Orange +2'),
								},
							}}
						>
							<FlexBox justifyContent='center' alignItems={'center'}>
								<LencoTypography variant='H7_Medium' color={getColor('')}>
									{data?.textItems?.rent?.subtitle?.text}
								</LencoTypography>
								<Box ml='8px'>
									<RightArrowIcon />
								</Box>
							</FlexBox>
						</Box>
					</Link>
					{data?.textItems?.unlmtd?.title && (
						<Link href={'/unlmtd'}>
							<Box
								sx={{
									background: '#57275B',
									minWidth: '272px',
									padding: '16px 32px 16px 40px',
									cursor: 'pointer',
								}}
							>
								<FlexBox justifyContent='center' alignItems={'center'}>
									<LencoTypography variant='H7_Medium' color={getColor('')}>
										{data?.textItems?.unlmtd?.subtitle?.text || 'UNLMTD'}
									</LencoTypography>
									<Box ml='8px'>
										<RightArrowIcon />
									</Box>
								</FlexBox>
							</Box>
						</Link>
					)}
				</FlexBox>
			</FlexBox>
		</Box>
	);
}

export default BuyRentWidget;
