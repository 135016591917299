import React, { useState } from 'react';
import { FaqData } from './modal';
import styles from './faq.module.css';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Typography,
	useTheme,
} from '@mui/material';
import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import WidgetHeader from '@/components/Headers/WidgetHeader';

const FaqComponent = ({ data }: FaqData) => {
	const theme = useTheme();
	const [expanded, setExpanded] = useState<any>(true);
	const [viewMore, setViewMore] = useState(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : true);
		};

	const toggleViewMore = () => {
		setViewMore(!viewMore);
	};

	return (
		<FlexBox sx={{ justifyContent: 'center', alignItems: 'center' }}>
			<Box sx={{ width: '50%' }}>
				{data.headers && (
					<Box sx={{ textAlign: 'center' }}>
						<WidgetHeader {...data.headers} />
					</Box>
				)}
				<Box className={styles.faqContent}>
					{data?.components
						?.slice(
							0,
							viewMore
								? data.components.length
								: data.contentFormat?.visibleFaqCount
						)
						.map((it, index: number) => {
							const item = it?.data;
							return (
								<Accordion
									key={item.id}
									expanded={expanded === item.id}
									onChange={handleChange(item.id)}
									sx={{
										background: 'transparent',
										borderBottom: `2px dashed ${theme?.colors?.lencoPrimaryP5}`,
										boxShadow: 'none',
									}}
								>
									<AccordionSummary
										expandIcon={
											<LencoImage
												url={data?.contentFormat?.expandIcon}
												containerSx={{ width: '16px' }}
											/>
										}
										aria-controls={`panel1bh-content-${item.id}`}
										id={`panel1bh${item.id}-header`}
										sx={{ padding: '0px' }}
									>
										<Typography
											sx={{
												...getStyle(data?.contentFormat?.questionFont),
												color: theme?.colors?.lencoPrimaryN6,
												flexShrink: 0,
												// ...getStyle('H14_Medium'),
											}}
											className={styles.question_content}
										>
											{item.question}
										</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ paddingLeft: '0px' }}>
										<Typography
											sx={{
												...getStyle(data?.contentFormat?.answerFont),
												color: theme?.colors?.lencoPrimaryP5,
												// ...getStyle('P_Regular'),
											}}
										>
											{item.answer}
										</Typography>
									</AccordionDetails>
								</Accordion>
							);
						})}
				</Box>
				<FlexBox
					sx={{
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						mt: '20px',
					}}
				>
					<Box sx={{ width: '30%' }}>
						<Button
							onClick={() => toggleViewMore()}
							sx={{
								background: theme?.colors?.lencoSecondaryP1,
								color: getColor('gray-5'),
								maxHeight: '35px',
								...getStyle('Small-Regular'),
								':hover': {
									background: theme?.colors?.lencoSecondaryP1,
								},
							}}
						>
							{viewMore ? 'See Less' : 'See More'}
						</Button>
					</Box>
				</FlexBox>
			</Box>
		</FlexBox>
	);
};

export default FaqComponent;
