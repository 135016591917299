import SpotlightCarousel from '@/atomic-components/carousels/spotlight-carousel';
import WidgetFooter from '@/components/Footers/WidgetFooter';
import WidgetHeader from '@/components/Headers/WidgetHeader';
import { componentMapper } from '@/utils/componentMapper';
import { Box } from '@mui/material';
import Link from 'next/link';

function CarouselWithFiveTiles(props: any) {
	const { components } = props.data;

	return (
		<Box>
			{props?.data?.headers && <WidgetHeader {...props.data.headers} />}
			<SpotlightCarousel
				spaceBetween={10}
				slidesPerView={5}
				dots={true}
				centered={true}
			>
				{components?.map((component: any, index: number) => {
					const DynamicComponent = componentMapper(component);
					return 	<DynamicComponent.component key={DynamicComponent.key} {...component} />
					// return (
					// 	// <Box key={`${DynamicComponent.key}-${index}`}>
					// 	// 	<DynamicComponent.component {...component} />
					// 	// </Box>
					// );
				})}
			</SpotlightCarousel>
			{props?.data?.footer && <WidgetFooter {...props?.data?.footer}/>}
		</Box>
	);
}

export default CarouselWithFiveTiles;
