

const reduceWebOrBrowserLink = (url: string) => {
  if (url.includes('/web_url?link=')) {
    return url.replace('/web_url?link=', '');
  }

  if (url.includes('/browser_url?link=')) {
    return url.replace('/browser_url?link=', '');
  }

  return url;
};

export const reduceLinkToUsableForm = (url: string) => {
  let reducedUrl = reduceWebOrBrowserLink(url);

  return reducedUrl;
};